export const TEXT_FIELD_MIN_LENGTH = 2;
export const TEXT_FIELD_MAX_LENGTH = 30;
export const TRADE_COMMODITY_MAX_LENGTH = 309;
export const EMAIL_MAX_LENGTH = 129;
export const PHONE_FIELD_MIN_LENGTH = 9;
export const YEARS_FIELD_MIN_LENGTH = 1;
export const MESSAGE_MAX_LENGTH = 1000;

export const ONLY_SPACES_REGEX = /^\s+$/;
export const DIGITS_ONLY = /\d/;
export const NOT_ONLY_SPECIAL_CHARACTERS = /[A-Za-z0-9éàèùâêîôûëïüçæœ«»]+/;
export const NOT_START_OR_END_WITH_SPECIAL_CHARACTERS = /^\s*[a-zA-Z0-9éàèùâêîôûëïüçæœ«»].*[a-zA-Z0-9éàèùâêîôûëïüçæœ«»"]\s*$|^\s*[a-zA-Z0-9éàèùâêîôûëïüçæœ«»"]?\s*$/;
export const ONLY_LETTERS_DIGITS_SPACES_DASHES = /^[0-9A-Za-z\s-éàèùâêîôûëïüçæœ«»"]+$/;
export const ONLY_LETTERS_DIGITS_SPACES_DASHES_DECIMAILS = /^[0-9A-Za-z\s-éàèùâêîôûëïüçæœ«»".]+$/;
export const ONLY_DIGITS_DASHES_DOTS_SPACES_COMMAS = /^[0-9\-.,\s]+$/;
export const ONLY_LETTERS_DASHES_SPACES_COMMAS = /^[A-Za-z\s,-éàèùâêîôûëïüçæœ«»"]+$/;
export const ONLY_STRICT_LETTERS_DASHES_SPACES_COMMAS = /^[A-Za-z\s,-éàèùâêîôûëïüçæœ]+$/;
export const EmailRegExp = /^\s*(?!\.)(?!.*\.$)(?!.*\.\.)[a-zA-Z0-9._+-]{1,64}@([a-zA-Z0-9.-]{1,62}\.[a-zA-Z]{2,})\s*$/;

export const LENGTH_ERROR = ({field, mustBeFrom, min, to, max, characters}) => {
    return `${field} ${mustBeFrom} ${min} ${to} ${max} ${characters}`
};
